import * as Yup from "yup";
import { StepId } from "../types/CreateDocumentSteps";
import { TAG_TEMPLATE_REGEX } from "../constants/tagTemplateRegex";
import { messages } from "../../../../constants/messages";

const dependantSteps = {
  [StepId.UPLOAD]: "uploadDocumentsAllowed",
};

export const subTemplateValidationSchema = (allSubTemplateNames: string[]) =>
  Yup.object().shape({
    id: Yup.string(),
    name: Yup.string()
      .test(
        "unique-name",
        "SubTemplate with this name already exists",
        function test(value) {
          if (!value) return true;
          return !allSubTemplateNames.includes(value);
        }
      )
      .required(),
    isActive: Yup.boolean().required(),
    hasPwcContact: Yup.boolean().required(),
    hasPwcSigner: Yup.boolean().required(),
    uploadDocumentsAllowed: Yup.boolean().required(),
    allowRedlining: Yup.boolean().required(),
    documentConfiguration: Yup.string(),
    docBotTemplateId: Yup.string().required(),
    editorNotesId: Yup.string(),
    guidances: Yup.array()
      .of(
        Yup.object().shape({
          guidanceId: Yup.string().required(messages.form.required),
          stepName: Yup.string().test(
            "selected-step-enabled-with-subTemplate-configuration",
            "Selected step doesn't exist in this SubTemplate. Please update SubTemplate configuration or select another step",
            (stepId, context) => {
              if (stepId && Object.keys(dependantSteps).includes(stepId)) {
                return context.options.context?.[dependantSteps[stepId]];
              }
              return true;
            }
          ),
          stepSection: Yup.string(),
        })
      )
      .test(
        "every-guidance-has-selected-step",
        "Guidance without selected step and subStep will override other selections",
        (guidances) => {
          if (!guidances || guidances?.length === 1) return true;
          return guidances?.every((guidance) => guidance.stepName);
        }
      )
      .test(
        "every-guidance-with-same-step-has-selected-unique-subStep",
        "Guidances for the same Steps must have unique selection for SubSteps",
        (guidances) => {
          if (!guidances || guidances?.length === 1) return true;

          return !guidances.some(
            (obj, idx) =>
              guidances.findIndex(
                (i) =>
                  (i.stepName === obj.stepName &&
                    i.stepSection === obj.stepSection) ||
                  (i.stepName === obj.stepName && !obj.stepSection)
              ) !== idx
          );
        }
      ),
    documentSampleFileName: Yup.string().test(
      "has-extension",
      "File name must contain an extension.",
      function test(value) {
        if (!value) return true;
        return value.includes(".docx");
      }
    ),
    documentSampleFile: Yup.mixed(),
    tags: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(messages.form.required),
        signerRoleId: Yup.string().required(messages.form.required),
        template: Yup.string()
          .required(messages.form.required)
          .test(
            "valid-template",
            "If template has '{' it must have closing '}'. Valid value inside braces is number 0 or 1",
            function test(value) {
              if (!value) return true;
              return TAG_TEMPLATE_REGEX.test(value);
            }
          ),
        type: Yup.string().required(messages.form.required),
        dataType: Yup.string().required(messages.form.required),
      })
    ),
  });
