export const createTemplateFormData = (template: Record<string, any>) => {
  const modalPayload = { ...template };
  const formData = new FormData();
    modalPayload.subTemplates.forEach((subTemplate, idx) => {
    if (subTemplate.documentSampleFile) {
      formData.append(
        `SubTemplateFiles[${idx}].File`,
        subTemplate.documentSampleFile
      );

      formData.append(
        `SubTemplateFiles[${idx}].SubTemplateName`,
        subTemplate.name
      );
    }
    if (
      !subTemplate.documentSampleFile &&
      !subTemplate.documentSampleFileName
    ) {
      formData.append(
        `SubTemplateFiles[${idx}].SubTemplateName`,
        subTemplate.name
      );
    }
    delete subTemplate.documentSampleFileName;
    delete subTemplate.documentSampleFile;
  });

  formData.append("Model", JSON.stringify(modalPayload));

  return formData;
};
