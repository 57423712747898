import { createContext, PropsWithChildren, useEffect, useState } from "react";
import IUserProps from "../types/IUserProps";

export interface IUserContext {
  currentUser?: IUserProps;
  setCurrentUser: (user?: IUserProps) => void;
}

export interface IUserContextProvider {
  user?: IUserProps;
}

export const UserContext = createContext<IUserContext>({} as IUserContext);

export default function UserContextProvider({
  user,
  children,
}: PropsWithChildren<IUserContextProvider>) {
  const [currentUser, setCurrentUser] = useState<IUserProps | undefined>(user);

  const context: IUserContext = {
    currentUser,
    setCurrentUser,
  };

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
}
