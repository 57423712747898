import { SignerRole } from "../../../../types/SignerRole";
import { TagDataType } from "../../../../types/TagDataType";

export const SIGNER_ORDER = {
    [SignerRole.CLIENT_SIGNER]: 1,
    [SignerRole.CLIENT_SPOUSE]: 2,
  };
  
export const TAG_TYPE_ORDER = {
    [TagDataType.SIGNATURE]: 1,
    [TagDataType.DATE]: 2,
  };