import hash from 'hash.js';
import base64url from 'base64url';
import { Buffer } from 'buffer';

import { generate } from 'randomstring';
import Cookie from 'universal-cookie';
import apiConfig from '../../api/apiConfig';

const accessTokenCacheKey = apiConfig.ACCESS_TOKEN_KEY;
const accessTokenExpirationCacheKey = accessTokenCacheKey + '-expiration';

const Cookies = new Cookie();

export const clearToken = () => {
  sessionStorage.removeItem(accessTokenCacheKey);
  sessionStorage.removeItem(accessTokenExpirationCacheKey);
};

export const tokenIsValid = () => {
  const accessToken = sessionStorage.getItem(accessTokenCacheKey);
  const accessTokenExpiration = sessionStorage.getItem(
    accessTokenExpirationCacheKey
  );
  return (
    accessToken &&
    accessTokenExpiration &&
    new Date(accessTokenExpiration) > new Date()
  );
};

export const readSdkToken = () => {
  return Cookies.get(".Sdk.accessToken") || sessionStorage.getItem(".Sdk.accessToken");
}

export const createSdkToken = () => {
  if (!sessionStorage.getItem(".Sdk.accessToken")) {
    const token = sessionStorage.getItem(accessTokenCacheKey);
    if(token !== null){
    sessionStorage.setItem(".Sdk.accessToken", token);
    }
    else
      throw Error(`'${accessTokenCacheKey}' token not found in sessionStorage!`);
  }
}

export const generateProofKeyCodeForExchange = () => {
  //proof key
  const codeVerifier = generate(64);

  //encoded proof key challenge: base64ulr(sha256(ascii(key)))
  const digest = hash.sha256().update(codeVerifier).digest();
  (window as any).Buffer = Buffer;
  const codeChallenge = base64url(digest as any);

  return {
    codeVerifier,
    codeChallenge
  };
}
