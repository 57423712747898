import { PropsWithChildren, ReactNode, useEffect, useState } from "react";
import apiConfig from "../../api/apiConfig";
import { apiHelper } from "../../api/apiHelper";
import IUserProps from "../../types/IUserProps";
import {
  clearToken,
  createSdkToken,
  readSdkToken,
  tokenIsValid,
} from "./helpers";
import launchPendo from "../../helpers/launchPendo";

const profileUrl = "/api/identity/profile";

interface IProps {
  authenticationCallbackPath: string;
  useSdkToken?: boolean;
  replicateSdkToken?: boolean;
  loadingComponent?: ReactNode;
  setUser: (user: IUserProps) => void;
}

const AuthenticationWrapper = ({
  authenticationCallbackPath,
  useSdkToken = false,
  replicateSdkToken = false,
  loadingComponent = <span>Authentication...</span>,
  setUser,
  children,
}: PropsWithChildren<IProps>) => {
  const pendoKey = process.env.REACT_APP_PENDO_API_KEY;

  const [authenticated, setAuthenticated] = useState(false);
  const [authenticateUrl, setAuthenticateUrl] = useState<string>();

  useEffect(() => launchPendo(pendoKey), []);

  useEffect(() => {
    if (
      authenticationCallbackPath &&
      !window.location.pathname.includes(authenticationCallbackPath)
    ) {
      const valid = validateToken();

      if (valid) {
        apiHelper(profileUrl, { redirect: "error" })
          .then((r) => {
            r.json().then((data: any) => {
              let isPwc = false;
              if (data.isPwc !== undefined) isPwc = data.isPwc === "true";
              else isPwc = data.email.includes("pwc.com");
              setUser({
                name: data.name,
                email: data.email,
                isPwC: isPwc,
                role: data.role,
              });

              // @ts-ignore
              window.pendo && window.pendo.initialize({
                  visitor: {
                    id: data.uid,
                    name: data.name,
                    email: data.email,
                    role: data.role,
                    internalUser: isPwc,
                    country: "US",
                    lineOfService: "Internal Firm Services",
                    pageUrl: window.location.href,
                    browserInfo: navigator.userAgent,
                  },
                  account: {
                    id: pendoKey,
                  },
                });
            });
            setAuthenticated(true);
            if (replicateSdkToken) {
              createSdkToken();
            }
          })
          .catch((e) => {
            clearToken();
            authenticate();
          });
      } else {
        clearToken();
        authenticate();
      }
    }

    const handleCallback = (e) => {
      if (e.data && e.data.source === "ep-auth-callback") {
        setAuthenticated(true);
      }
    };

    window.addEventListener("message", handleCallback);
    return () => {
      window.removeEventListener("message", handleCallback);
    };
  }, [authenticationCallbackPath]);

  const validateToken = () => {
    let valid = tokenIsValid();
    if (!valid && useSdkToken) {
      apiConfig.USE_SDK_TOKEN = !!useSdkToken;

      if (readSdkToken()) valid = true;
    }
    return valid;
  };

  const authenticate = () => {
    if (process.env.REACT_APP_PWC_ID_SERVER_URL) {
      const params = [
        "client_id=" + process.env.REACT_APP_PWC_ID_C4T_CLIENT_ID,
        "redirect_uri=" +
          process.env.REACT_APP_MAIN_API +
          process.env.REACT_APP_PWC_ID_REDIRECT_URL,
        "state=" +
          encodeURIComponent(window.location.pathname + window.location.search),
        "scope=openid profile email cloudEmail PwCPPI",
        "response_type=code",
      ];
      const url =
        `${process.env.REACT_APP_PWC_ID_SERVER_URL}/openam/oauth2/authorize?` +
        params.join("&");
      setAuthenticateUrl(url);
    }
  };

  if (!authenticated && authenticateUrl) {
    window.location.replace(authenticateUrl);
  }

  return <>{authenticated ? children : loadingComponent}</>;
};

export default AuthenticationWrapper;
