import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Entity } from "../types/Entity";
import { Api } from "../api/apiHelper";
import { DocBotTemplate } from "../types/Template";
import { getDocBotTemplates } from "../api/endpoints/admin";
import { subCategories as subCategoriesApi } from "../api/endpoints/categories";
import { guidances as guidancesApi } from "../api/endpoints/guidances";
import { exhibitConfigurations } from "../api/endpoints/exhibits";
import { editorNotes as editorNotesApi } from "../api/endpoints/editorNotes";
import { EditorNote } from "../pages/Admin/EditorNotes/helpers/types";

type TemplateContextType = {
  editorNotes: Entity[];
  guidances: Entity[];
  subCategories: Entity[];
  exhibits: Entity[];
  docBotTemplates: DocBotTemplate[];
};

type TemplateContextProviderProps = {
  children: React.ReactNode;
};

const TemplateContext = React.createContext<TemplateContextType>(
  {} as TemplateContextType
);

const TemplateContextProvider: React.FC<TemplateContextProviderProps> = (
  props
) => {
  const { pathname } = useLocation();

  const [docBotTemplates, setDocBotTemplates] = useState<DocBotTemplate[]>([]);
  const [editorNotes, setEditorNotes] = useState<Entity[]>([]);
  const [guidances, setGuidances] = useState<Entity[]>([]);
  const [subCategories, setSubCategories] = useState<Entity[]>([]);
  const [exhibits, setExhibits] = useState<Entity[]>([]);

  const templateContext: TemplateContextType = {
    editorNotes,
    guidances,
    subCategories,
    exhibits,
    docBotTemplates,
  };

  useEffect(() => {
    if (!pathname.includes("templates")) return;

    Api.get(getDocBotTemplates).then((res) => setDocBotTemplates(res));

    Api.get(editorNotesApi).then((res) =>
      setEditorNotes(res.map((i: EditorNote) => ({ id: i.id, name: i.title })))
    );

    Api.get(subCategoriesApi).then((res) => setSubCategories(res));

    Api.get(guidancesApi).then((res) => setGuidances(res));

    Api.get(exhibitConfigurations).then((res) => setExhibits(res));
  }, [pathname]);

  return (
    <TemplateContext.Provider value={templateContext}>
      {props.children}
    </TemplateContext.Provider>
  );
};

export const useTemplateContext = (): TemplateContextType =>
  useContext(TemplateContext);

export default TemplateContextProvider;
