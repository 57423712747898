import { Avatar, DropdownButton } from "@appkit4/react-components";
import { useContext } from "react";
import { UserContext } from "../../../contexts/UserContext";
import { getInitials } from "../../../helpers/getInitials";
import apiConfig from "../../../api/apiConfig";
import { Api } from "../../../api/apiHelper";
import { logout } from "../../../api/endpoints/logout";
import { DROPDOWN_OPTIONS } from "./constants";
import { UserDropdownOption } from "./types";
import { clearToken } from "../../Authentication/helpers"

export const UserTemplate = () => {
  const { currentUser } = useContext(UserContext);
  const userInitials = currentUser ? getInitials(currentUser?.name) : "";

  const accessToken = sessionStorage.getItem(apiConfig.ACCESS_TOKEN_KEY);

  const selectActions = {
    [UserDropdownOption.LOGOUT]: () => {
      Api.get(logout, { accessToken }).then(() => {
        clearToken();
        const redirectURL = process.env.REACT_APP_LOGOUT_REDIRECT_URL;
        if (!redirectURL) return;
        window.location.replace(redirectURL);
      });
    },
  };

  return (
    <DropdownButton
      customTriggerNode={true}
      onSelect={(value) => selectActions[value]()}
      data={DROPDOWN_OPTIONS}
      prefixTemplate={(item) => (
        <span className={`Appkit4-icon icon-${item.iconName}`}></span>
      )}
    >
      <Avatar size={32} label={userInitials}></Avatar>
    </DropdownButton>
  );
};
