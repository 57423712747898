import { Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { SpaContainer } from "./sdk-framework/SpaContainer";
import guestConfigs from "../configs/guest-configs.json";
import { SdkPubSub } from "@pwc-sdk/spa-utils";
import Admin from "../pages/Admin/Home";
import UserContextProvider from "../contexts/UserContext";
import IUserProps from "../types/IUserProps";
import Home from "./Home";
import AppHeader from "./AppHeader";
import Categories from "../pages/Admin/Categories";
import EditorNotes from "../pages/Admin/EditorNotes";
import { AdminLayout } from "./AdminLayout";
import { AppKitToastContainer } from "./Toast";
import ConfirmationModalContext from "../contexts/ConfirmationModalContext";
import PageSpinner from "./PageSpinner";
import { Templates } from "../pages/Admin/Templates";
import Users from "../pages/Admin/Users";
import { Forms } from "../pages/Admin/Forms";
import { EditForm } from "../pages/Admin/Forms/EditForm";
import AppContextProvider from "../contexts/AppContext";
import Guidances from "../pages/Admin/Guidances";
import TemplateForm from "../pages/Admin/Templates/components/TemplateForm";
import TemplateContextProvider from "../contexts/TemplateContext";
import { ROUTES } from "../constants/routes";
import { DocBotTemplates } from "../pages/Admin/DocBotTemplates";
import ApplicationSettings from "../pages/Admin/AppSettings";

const PagePlaceholder = () => <div>In Development</div>;

const sdkPubSub = new SdkPubSub();
sdkPubSub.register("C4T Props change", "c4t-track-props");

interface IContentProps {
  user?: IUserProps;
}

const Content = ({ user }: IContentProps) => {
  let guestsConfig =
    guestConfigs?.[process.env.REACT_APP_CONFIG_ENV ?? "local"];

  // Replacing ##SAS_TOKEN## to real value supposing token can be in mulitple places
  guestsConfig = JSON.parse(
    JSON.stringify(guestsConfig)?.replace(
      /##SAS_TOKEN##/g,
      process.env.REACT_APP_CDN_SAS_TOKEN ?? ""
    )
  );
  const trackConfig = guestsConfig.find(
    (c) => c.name === "pwc-c4t-track-document"
  );

  return (
    <UserContextProvider user={user} key={user?.email}>
      <AppContextProvider>
        <ConfirmationModalContext>
          <TemplateContextProvider>
            <div className="d-flex flex-column full-height">
              <AppHeader />
              <div className="content-wrapper ap-bg-color-background-default">
                <Routes>
                  <Route
                    key={trackConfig.name}
                    path={trackConfig.path}
                    element={
                      <SpaContainer
                        className="c4t-spa-container ap-full-height"
                        guestConfig={trackConfig}
                        user={user}
                        onProgressComponent={<PageSpinner />}
                        onErrorComponent={
                          <div className="d-flex">MFE loading error!</div>
                        }
                      />
                    }
                  />
                  <Route
                    key="admin"
                    path="admin"
                    element={<AdminLayout user={user} />}
                  >
                    <Route index element={<Admin />} />
                    <Route path={ROUTES.USERS.path} element={<Users />} />
                    <Route
                      path={ROUTES.CATEGORIES.path}
                      element={<Categories />}
                    />
                    <Route
                      path={ROUTES.GUIDANCES.path}
                      element={<Guidances />}
                    />
                    <Route
                      path={ROUTES.EDITOR_NOTES.path}
                      element={<EditorNotes />}
                    />
                    <Route path={ROUTES.TEMPLATES.path}>
                      <Route index element={<Templates />} />
                      <Route path=":templateId" element={<TemplateForm />} />
                      <Route
                        path={ROUTES.CREATE_TEMPLATE.path}
                        element={<TemplateForm />}
                      />
                    </Route>
                    <Route path={ROUTES.FORMS.path}>
                      <Route index element={<Forms />} />
                      <Route path="new" element={<EditForm />} />
                      <Route path=":formId" element={<EditForm />} />
                    </Route>

                    <Route
                      path={ROUTES.DOC_BOT_TEMPLATES.path}
                      element={<DocBotTemplates />}
                    />
                    <Route
                      path={ROUTES.SETTINGS.path}
                      element={<ApplicationSettings />}
                    />
                  </Route>

                  <Route path="home" element={<Home />} />
                </Routes>
              </div>

              <AppKitToastContainer />
            </div>
          </TemplateContextProvider>
        </ConfirmationModalContext>
      </AppContextProvider>
    </UserContextProvider>
  );
};

export default Content;
