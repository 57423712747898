import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import guestConfigs from "./configs/guest-configs.json";
import "./App.scss";
import { SdkPubSub } from "@pwc-sdk/spa-utils";
import AuthenticationCallback from "./components/Authentication/AuthenticationCallback";
import AuthenticationWrapper from "./components/Authentication/AuthenticationWrapper";
import IUserProps from "./types/IUserProps";
import Content from "./components/Content";

const sdkPubSub = new SdkPubSub();
sdkPubSub.register("C4T Props change", "c4t-track-props");

const App = () => {
  const authenticationCallbackPath = "/create-for-tax/signin-oidc";
  //process.env.REACT_APP_PWC_ID_REDIRECT_URL ?? '';
  const [user, setUser] = useState<IUserProps>();

  return (
    <Routes>
      <Route
        key="auth-callback"
        path={authenticationCallbackPath}
        element={<AuthenticationCallback />}
      />
      <Route
        key="app-layout"
        path="*"
        element={
          <AuthenticationWrapper
            authenticationCallbackPath={authenticationCallbackPath}
            loadingComponent={<div>Authentication...</div>}
            setUser={setUser}
          >
            <Content user={user} />
          </AuthenticationWrapper>
        }
      />
    </Routes>
  );
};

export default App;
