import { useEffect } from "react";
import apiConfig from "../../api/apiConfig";

type Props = {
  onReadToken?: (token: string, expiresInSeconds: number) => void;
};

const AuthenticationCallback = ({ onReadToken: onReadAccessToken }: Props) => {
  const accessTokenCacheKey =
    process.env.REACT_APP_JWT_TOKEN_CACHE_KEY ?? "C4T_ACCESS_TOKEN";
  const accessTokenExpirationCacheKey = accessTokenCacheKey + "-expiration";

  useEffect(() => {
    const accessToken = getQueryStringParameter("access_token");
    const redirectPath = getQueryStringParameter("state");
    const expiresIn = getQueryStringParameter("expires_in");
    //const idToken = getQueryStringParameter("id_token");

    if (!accessToken)
      throw Error("access_token is missing in OpenAM response!");
    if (!redirectPath)
      throw Error(
        'Redirect path ("state") parameter is missing in OpenAM reponse!'
      );
    if (!expiresIn)
      throw Error('Expiration ("expires_in") is missing in OpenAM response!');

    sessionStorage.setItem(accessTokenCacheKey, accessToken);

    let expiresInTime = new Date();
    expiresInTime.setSeconds(expiresInTime.getSeconds() + parseInt(expiresIn));
    sessionStorage.setItem(
      accessTokenExpirationCacheKey,
      expiresInTime.toISOString()
    );

    onReadAccessToken && onReadAccessToken(accessToken, parseInt(expiresIn));

    window.location.href = redirectPath;
  }, []);

  const getQueryStringParameter = (name) => {
    var url = new URL(window.location.href);
    return url.searchParams.get(name);
  };

  return <div>Redirecting...</div>;
};

export default AuthenticationCallback;
